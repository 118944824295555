<template>
  <div>
    <div class="banner">
      <Skeleton height="35px" width="100%" />
    </div>
    <div class="typeList">
      <Skeleton height="25px" />
      <Skeleton height="25px" />
      <Skeleton height="25px" />
      <Skeleton height="25px" />
      <Skeleton height="25px" />
    </div>
    <div class="card">
      <div class="item">
        <div class="img">
          <Skeleton height="200px" width="100%" />
        </div>
        <div class="title">
          <Skeleton height="23px" width="50%" />
          <Skeleton height="18px" width="80%" />
        </div>
      </div>
      <div class="item">
        <div class="img">
          <Skeleton height="200px" width="100%" />
        </div>
        <div class="title">
          <Skeleton height="23px" width="50%" />
          <Skeleton height="18px" width="80%" />
        </div>
      </div>
      <div class="item">
        <div class="img">
          <Skeleton height="200px" width="100%" />
        </div>
        <div class="title">
          <Skeleton height="23px" width="50%" />
          <Skeleton height="18px" width="80%" />
        </div>
      </div>
      <div class="item">
        <div class="img">
          <Skeleton height="200px" width="100%" />
        </div>
        <div class="title">
          <Skeleton height="23px" width="50%" />
          <Skeleton height="18px" width="80%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../../components/skeleton';
export default {
  components: { Skeleton },
  name: 'HomeLoading',
};
</script>

<style lang="less" scoped>
.banner {
  padding: 0 20px;
  margin-top: 20px;
}
.typeList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .skeleton {
    margin: 0 20px;
    flex: 1;
  }
}
.card {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .item {
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .title {
      display: flex;
      flex-direction: column;
      .skeleton {
        margin-top: 5px;
      }
    }
  }
}
</style>
