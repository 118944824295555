<template>
  <CategoryLoading v-if="isInitLoading" />
  <div class="category" v-else>
    <div>
      <form
        class="search"
        @click="$router.push('/search')"
        action=""
        @submit.prevent="search"
      >
        <input
          placeholder="搜索商品名称"
          type="search"
          v-model="name"
          autocomplete="off"
          @keyup.enter="searchList"
        />
        <img
          src="../../../static/image/sousuo.png"
          alt=""
          @click="searchList"
        />
        <!-- <div class="close">取消</div> -->
      </form>
      <div>
        <div class="anyTypelist_box">
          <van-tabs v-model="activeTab">
            <van-tab
              v-for="list in anyTypelist"
              :title="list.name"
              :key="list.id"
            >
            </van-tab>
          </van-tabs>
        </div>
        <div v-if="labelList.length">
          <div class="labelBox">
            <div
              class="labelItem"
              :class="item.active ? 'active' : null"
              v-for="item in labelList"
              :key="item.id"
              @click="changeLabel(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <van-list
        v-if="anyGoodmarketlist.length"
        class="content"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        loading-text="数据加载中..."
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="scroll">
          <div
            class="shop_img"
            v-for="item in anyGoodmarketlist"
            :key="item.id"
            @click="bazaar(item)"
          >
            <div class="img">
              <img :src="item.listPic" alt="" />
              <div class="bg" v-if="item.is_retreat">
                <img src="../../assets/images/yituishi.png" alt="" />
              </div>
            </div>
            <div class="shop_text">{{ item.name }}</div>
            <!-- <div class="info" v-if="item.bottom == item.top">
                  {{ item.nickName }}
                </div> -->
            <div v-if="item.bottom == item.top">
              <div class="shop_mongy">
                <span class="info">{{ item.nickName }}</span>
              </div>
            </div>
            <div v-if="item.bottom == item.top">
              <div class="shop_mongy">
                <span class="shop_sem">￥</span>
                <span class="pic">{{ item.currentPrice }}</span>
                <!-- item.is_retreat ? '--' : item.currentPrice -->
              </div>
            </div>
            <div v-else>
              <div
                class="shop_mongy"
                style="display: flex; padding-right: 10px; align-items: center"
              >
                <div style="flex: 1; margin-left: 0; min-width: 0">
                  <span class="shop_sem">￥</span>
                  <span class="pic">{{ item.top }}</span>
                </div>
                <span class="tag high">
                  <img src="../../assets/images/top.png" alt="" />
                </span>
              </div>
              <div
                class="shop_mongy"
                style="display: flex; padding-right: 10px; align-items: center"
              >
                <div style="flex: 1; margin-left: 0; min-width: 0">
                  <span class="shop_sem">￥</span>
                  <span class="pic">{{ item.bottom }}</span>
                </div>
                <span class="tag low">
                  <img src="../../assets/images/bottom.png" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div class="def" v-if="!anyGoodmarketlist.length">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>更多商品，敬请期待</p>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryLoading from "./CategoryLoading.vue";
export default {
  components: { CategoryLoading },
  data() {
    return {
      isInitLoading: true,
      finished: false,
      loading: false,
      add: 0,
      activeTab: 0,
      //active: 2, //下标
      anyGoodmarketlist: [], //市场列表
      anyTypelist: [], //市场导航栏
      params: {
        pageindex: 1,
        pagesize: 20,
      },
      name: "",
      isNotMore: false,
      labelList: [],
    };
  },
  watch: {
    activeTab(n) {
      const res = this.anyTypelist[n];
      this.add = res.id;
      this.params.pageindex = 1;
      this.finished = false;
      this.isNotMore = false;
      if (res.id == 2) {
        this.subscribeList();
      } else {
        this.getGoodmarketlist();
      }
      this.getcategory(this.add);
    },
  },
  methods: {
    changeLabel(item) {
      item.active = !item.active;
      this.params.pageindex = 1;
      this.finished = false;
      this.isNotMore = false;
      if (this.add != 2) {
        const ids = this.labelList.filter((e) => e.active).map((e) => e.id);
        this.getGoodmarketlist({ label: ids.join(",") });
      }
    },
    getcategory(type) {
      this.$api.getGoodsLabel({ type }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((e) => {
            e.active = false;
          });
          this.labelList = res.data;
        }
      });
    },
    onLoad() {
      if (!this.isNotMore) {
        this.params.pageindex++;
        this.getGoodmarketlist();
      } else {
        this.finished = true;
      }
    },
    subscribeList() {
      this.$api
        .subscribeList({
          pageindex: 1,
          pagesize: 50,
          token: localStorage.getItem("token"),
        })
        .then(
          (e) => {
            this.isInitLoading = false;
            this.loading = false;
            if (e.code == 0) {
              this.anyGoodmarketlist = e.data;
            }
          },
          () => {
            this.isInitLoading = false;
            this.loading = false;
          }
        );
    },
    // 商品详情
    bazaar(item) {
      this.$router.push({
        path: "/bazaar",
        query: {
          id: item.id,
          goodsId: item.goodsId,
          entrustId: item.entrust_id,
        },
      });
      // this.getGoodmarketlist();
    },
    search() {
      return false;
    },
    searchList() {
      this.isNotMore = false;
      this.params.pageindex = 1;
      this.params.pagesize = 5;
      this.getGoodmarketlist();
    },
    getGoodmarketlist(label) {
      if (this.isNotMore) return;
      let params = Object.assign(
        {
          name: this.name,
          type: this.add,
        },
        this.params,
        label || {}
      );
      const token = localStorage.getItem("token");
      if (token) {
        params.token = token;
      }
      this.$api.anyGoodmarketlist(params).then(
        (res) => {
          this.isInitLoading = false;
          this.loading = false;
          if (res.code == 0) {
            if (!res.data.length) {
              this.isNotMore = true;
            }
            if (this.params.pageindex == 1) {
              this.anyGoodmarketlist = res.data;
            } else {
              this.anyGoodmarketlist = this.anyGoodmarketlist.concat(res.data);
            }
          }
        },
        () => {
          this.isInitLoading = false;
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    this.isInitLoading = true;
    // 市场列表
    const token = localStorage.getItem("token");
    let params = {
      type: 1,
    };
    if (token) {
      params.token = token;
    }
    this.$api.anyTypelist(params).then((res) => {
      if (res.code == 0) {
        const findRes = res.data.filter((e) => e.id == 2);
        const findRes1 = res.data.filter((e) => e.id == 1);
        const list = res.data.filter((e) => e.id !== 1 && e.id !== 2);
        this.anyTypelist = [
          ...findRes,
          ...findRes1,
          {
            id: 0,
            name: "全部",
          },
          ...list,
        ];
        this.add = this.anyTypelist[0].id;
        if (this.add == 2) {
          this.subscribeList();
        } else {
          this.getGoodmarketlist();
        }
        // this.getGoodmarketlist();
        this.getcategory(this.add);
      }
    });
    // this.$api.anyGoodmarketlist(params).then((res) => {
    //   if (res.code == 0) {
    //     this.anyGoodmarketlist = res.data;
    //   }
    // });
  },
};
</script>
<style lang="less" scoped>
.category {
  height: 100vh;
  padding-bottom: 55px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
/deep/.van-tabs__line {
  display: none;
  // font-size: 20px;
}

/deep/.van-tabs {
  width: 100%;
}
/deep/.van-tab__text {
  font-weight: 600;
}
/deep/.van-tab--active {
  font-size: 20px;
  font-weight: 900;
}
.box {
  background-color: #f1f1f1;
  flex: 1;
  padding: 10px 0;
  overflow: hidden;
  .content {
    overflow: auto;
    height: 100%;
    .scroll {
      overflow: hidden;
    }
  }
}
.anyTypelist_box {
  display: flex;
}
.anyTypelist {
  color: #999999;
  font-size: 16px;
  margin-left: 10px;
  line-height: 50px;
}

.anyTypelist_tow {
  font-size: 19px;
  margin-left: 20px;
  font-weight: 600;
  line-height: 50px;
}

.shop_img1 {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  .img {
    margin: 0;
    position: relative;
    margin-right: 15px;
    .bg {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(98, 98, 98, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
        height: auto;
      }
    }
  }
  img {
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    vertical-align: middle;
  }
  .shop_text {
    font-size: 14px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #000000;
    margin-bottom: 20px;
  }
  .info {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .flex {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  .pic {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .left {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
      span {
        color: #fc9f28;
      }
    }
    .right {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      span {
        color: #fc9f28;
      }
    }
  }
}
.labelBox {
  align-items: center;
  display: flex;
  padding: 0 15px 10px;
  overflow: auto;
  .labelItem {
    white-space: nowrap;
    height: 30px;
    background: #f8f8f8;
    border-radius: 15px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #cecece;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin-right: 10px;
    border: 1px solid transparent;
    &.active {
      background: #fffbf8;
      border: 1px solid #f6b944;
      color: #f6b944;
    }
  }
}
.shop_img {
  background-color: #ffffff;
  margin-left: 17px;
  width: 163px;
  float: left;
  margin-bottom: 14px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(212, 210, 210, 0.192);
  div {
    margin-left: 8px;
  }
  .img {
    margin: 0;
    position: relative;
    .bg {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(98, 98, 98, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 140px;
        height: auto;
      }
    }
  }
  img {
    display: inline-block;
    width: 100%;
    height: 190px;
    border-radius: 5px;
    vertical-align: middle;
  }
  .shop_text {
    font-size: 14px;
    height: 42px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .info {
    font-size: 10px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  .shop_explain {
    font-size: 8px;
    color: #999999;
  }
  .shop_mongy {
    font-size: 13px;
    // padding-bottom: 15px;
    margin-left: 0;
    height: 22px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    .shop_sem {
      font-size: 10px;
    }
    .pic {
      font-size: 15px;
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }
    .tag {
      // font-size: 10px;
      // margin-left: 10px;
      // width: 101px;
      // height: 46px;
      // background: #f8f8f8;
      // color: #666666;
      // &.high {
      //   background: #fffbf8;
      //   color: #fab878;
      // }
      img {
        width: 10px;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}
.search {
  display: flex;
  height: 65px;
  align-items: center;
  position: relative;
}
.search > img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 30px;
}
.search > input {
  flex: 1;
  height: 33px;
  background-color: #f3f4f8;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  padding-left: 16px;
  margin: 0 12px;
}
.close {
  font-size: 14px;
  color: #ddd;
  margin: 0 10px;
}
.def {
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
</style>
